import Compressor from "compressorjs";
<template>
    <page class="user-page" title="我的" :hasBack="false" textAlign="center" :hasPadding="false">
        <nav-page :roleType="2">
            <div class="header">
                <img class="bg" src="@/assets/images/user/teacher_header_bg.png" alt="" />
                <div class="user-msg">
                    <van-uploader :before-read="beforeRead">
                        <img v-if="!userMsg.avatar" src="@/assets/images/user/default_avatar.png" alt="" />
                        <van-image v-else  class="vant-img" fit="cover" :src="userMsg.avatar" />
<!--                        <img v-else :src="userMsg.avatar" alt="" />-->
                    </van-uploader>
                    <div>
                        <div class="nickname">{{ userMsg.teacherName }}</div>
                        <div class="identity">{{ userMsg.identity }}</div>
                    </div>
                </div>
            </div>
            <div class="nav-wrapper">
                <div class="nav">
                    <div class="left">
                        <img src="@/assets/images/user/class.png" class="icon" alt="" />
                        <span>管理的班级</span>
                    </div>
                    <div class="right">
                        <span class="count">{{ userMsg.classNumber }}</span>
                        <span class="unit">个</span>
                    </div>
                </div>
                <common-block title="我的资料" :border="false">
                    <div class="teacher-msg">
                        {{ userMsg.profile }}
                    </div>
                </common-block>
            </div>
        </nav-page>
    </page>
</template>
<script>
import Page from '@/components/page/Page.vue';
import NavPage from '@/components/navPage/NavPage';
import CommonBlock from '@/components/commonBlock/CommonBlock';
import commonRequest from '@/services';
import Compressor from 'compressorjs';
import { reactive, ref } from 'vue';
import {Icon, Switch, Toast, Uploader} from 'vant';
import paramsParse from '@/utils/paramsParse';
import {getStorage} from "@/utils/storage";
import {Image as VanImage} from "vant/lib/image";
export default {
    name: 'User',
    components: {
        [Page.name]: Page,
        [NavPage.name]: NavPage,
        [Icon.name]: Icon,
        [Switch.name]: Switch,
        [CommonBlock.name]: CommonBlock,
        [Uploader.name]: Uploader,
        [VanImage.name]: VanImage,
    },
    setup() {
        const recordMenu = reactive([
            {
                name: '我的请假',
                link: '/',
                icon: require('@/assets/images/user/my_leave.png'),
            },
            {
                name: '我的考勤',
                link: '/myAttendance',
                icon: require('@/assets/images/user/my_attendance.png'),
            },
            {
                name: '我的成绩',
                link: '/myScore',
                icon: require('@/assets/images/user/my_score.png'),
            },
        ]);
        const bindClassCount = ref(2);
        const openStatus = ref(false);
        const userMsg = reactive({
            avatar: '',
            teacherName: '',
            identity: '老师',
            classNumber: 0,
            profile: '',
        });
        commonRequest({ action: 12005 }).then((res) => {
            if (res.data) {
                userMsg.avatar = res.data.headimg;
                userMsg.teacherName = res.data.teacherName;
                userMsg.classNumber = res.data.classNumber;
                userMsg.profile = res.data.profile;
            }
        });
        const beforeRead = (file) => {
            new Compressor(file, {
                quality: 0.6,
                success(result) {
                    // Blob 转换为 File
                    const files = new window.File([result], file.name, {type: file.type});
                    const timestamp = Math.floor(new Date().getTime() / 1000);
                    const signData = {
                        action: 10001,
                        type: 'images',
                        timestamp,
                    };
                    const token = getStorage('token');
                    if (token) {
                        signData.token = token;
                    }
                    const sign = paramsParse(signData);
                    const formData = new FormData();
                    formData.append('action', 10001);
                    formData.append('type', 'images');
                    formData.append('timestamp', timestamp);
                    formData.append('sign', sign);
                    formData.append('token', token);
                    formData.append('files', files);
                    const toast = Toast.loading({
                        message: '上传中...',
                        forbidClick: true,
                        loadingType: 'spinner',
                        duration: 0
                    });
                    commonRequest(formData).then((res) => {
                        if (res.data) {
                            const photo = res.data[0]?.aliyunPath;
                            commonRequest({ action: 12011, avatar: photo}).then(() => {
                                toast.clear();
                                Toast('修改成功');
                                userMsg.avatar = photo;
                            })
                        }
                    });
                }
            })

        };
        return {
            recordMenu,
            bindClassCount,
            openStatus,
            userMsg,
            beforeRead
        };
    },
};
</script>
<style lang="less" scoped>
.header {
    position: relative;
    .bg {
        width: 100%;
        display: block;
    }
}
.nav-wrapper {
    padding: 0 15px;
    margin-top: -26px;
    position: relative;
    z-index: 10;
}
.nav {
    height: 51px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 5px;
    .left {
        display: flex;
        height: 100%;
        align-items: center;
        img {
            width: 25px;
            margin-right: 4px;
        }
        span {
            font-size: 15px;
            font-family: PingFang SC, serif;
            color: #333333;
        }
    }
    .right {
        display: flex;
        align-items: center;
        padding-right: 12px;
        .count {
            font-size: 16px;
            font-family: PingFang SC, serif;
            font-weight: 800;
            line-height: 23px;
            color: #333333;
        }
        .unit {
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            line-height: 20px;
            color: #666666;
            margin-left: 5px;
        }
    }
}

.user-msg {
    position: absolute;
    top: 27px;
    left: 17px;
    display: flex;
    align-items: center;
    img {
        width: 49px;
        margin-right: 6px;
        border-radius: 50%;
    }
    .vant-img {
        width: 49px;
        height: 49px;
        margin-right: 6px;
        /deep/ .van-image__img {
            border-radius: 50%;
        }
    }
    .nickname {
        font-size: 12px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        line-height: 17px;
        color: #ffffff;
        height: 17px;
    }
    .identity {
        width: 50px;
        height: 18px;
        background: #333333;
        border-radius: 9px;
        font-size: 12px;
        font-family: PingFang SC, serif;
        font-weight: 400;
        color: #ffffff;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.teacher-msg {
    padding-right: 6px;
    padding-bottom: 17px;
    font-size: 13px;
    font-family: PingFang SC, serif;
    line-height: 19px;
    color: #666666;
}
</style>
